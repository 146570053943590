export default {
  SUPPLIERS_LIST: "Liste des fournisseurs",
  ADD_SUPPLIER: "Ajouter un fournisseur",
  SUPPLIER_ADDED: "Fournisseur ajouté",
  EDIT_SUPPLIER: "Modifier un fournisseur",
  SUPPLIER_UPDATED: "Founrnisseur mis à jour updated",
  DELETE_THIS_SUPPLIER: "Supprimer ce fournisseur ?",
  SUPPLIER_DELETED: "Fournisseur supprimé",
  BILLING_INFORMATIONS_SAME_AS_SUPPLIER: "Même adresse que le fournisseur",
  ADD_PRODUCT: "Ajouter un produit",
  ADD_SUPPLIER_FILE: "Ajouter un fichier produits",
  ADD_SUPPLIER_FILE_RESULTS: "Resultats de la synchronisation du fichier",
  RUN_SUPPLIER_FILE_SYNC: "Lancer l'import",
  SYNC_PREVIEW: "Aperçu de la synchronisation",
  PRODUCTS_CREATED: "Créés",
  PRODUCTS_UPDATED: "Mis à jour",
  PRODUCTS_DELETED: "Supprimés",
  PRODUCTS_ERRORS: "Erreurs",
  PRODUCTS_UNTOUCHED: "Produit intacts",
  PRODUCT: "Produit",
  ERROR: "Erreur",
  RUN_SYNCHRONISATION: "Lancer la synchronisation",
  SYNC_RESULTS: "Résultats de la synchronisation",
  LAST_SYNC_PRODUCTS: "Produits synchronisés",
  SHOW_FILE: "Voir le fichier",
  ADD_TO_WAREHOUSE_MODELS: "Ajouter aux modèles",
  DELETE_FROM_WAREHOUSE_MODELS: "Supprimer des modèles",
  PRODUCTS_ADDED: "Ajoutés",
  PRODUCTS_PROCESSED: "Produits traités",
  ACTIVE: "Actif",
  ACTIVATE: "Activer",
  DEACTIVATE: "Désactiver",
  SUPPLIER_ACTIVATED: "Fournisseur Activé",
  SUPPLIER_DEACTIVATED: "Fournisseur Désactivé",
};
