import store from "../store";
import swal from "sweetalert2";

const getProductUnitOfMeasureUnitPrice = {
  install: (Vue) => {
    Vue.prototype.$getProductUnitOfMeasureUnitPrice = async (
      product,
      unitOfMeasureUnit
    ) => {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const vProduct = await store.dispatch(
          "products/getProductUnitOfMeasureUnitPrice",
          { id: product.id, unitOfMeasureUnit: unitOfMeasureUnit }
        );
        swal.close();
        return vProduct;
      } catch (e) {
        console.log(e);
        swal.close();
        return product;
      }
    };
  },
};

export default getProductUnitOfMeasureUnitPrice;
