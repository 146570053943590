const objectDenomination = {
  install: (Vue) => {
    Vue.prototype.$objectDenomination = (object) => {
      if (
        [
          "products",
          "warehouse-products",
          "supplier-products",
          "warehouses",
          "cafeterias",
          "service-centers",
          "establishments"
        ].includes(object.type)
      ) {
        return `${object.name}`;
      }
      if (object.type === "customers") {
        return `${object.customer_name}`;
      }
      if (object.type === "suppliers") {
        return `${object.company_name}`;
      }
      if (object.type === "users") {
        return `${object.firstname} ${object.lastname}`;
      }
      if (object.type === "contacts") {
        return `${object.title} ${object.firstname} ${object.lastname}`;
      }
      if (["organizations", "resellers"].includes(object.type)) {
        return `${object.name}`;
      }
      if (["establishments"].includes(object.type)) {
        return `${object.company_name}`;
      }
      if (
        [
          "purchases-orders",
          "purchases-invoices",
          "sales-orders",
          "sales-invoices",
        ].includes(object.type)
      ) {
        return `${object.code}`;
      }
      if (["cash-register-reading-items"].includes(object.type)) {
        return `${object.product_name}`;
      }
      if (object.name) {
        return `${object.name}`;
      }
      return "NO DENOMINATION PLEASE UPDATE PLUGIN objectDenomination.js";
    };
  },
};

export default objectDenomination;
