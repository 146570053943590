<template>
  <div
    v-if="
      $currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS) &&
      serviceCenter &&
      serviceCenter.id
    "
  >
    <router-link
      :to="{ name: 'View ServiceCenter', params: { id: serviceCenter.id } }"
    >
      {{ `${serviceCenter.name}` }}
    </router-link>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "service-center",

  components: {},

  mixins: [formMixin],

  props: {
    serviceCenter: {
      type: Object,
      default: () => ({}),
      description: "serviceCenter",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    serviceCenter(serviceCenter) {},
  },
};
</script>
