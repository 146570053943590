import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const jsona = new Jsona();
const url = process.env.VUE_APP_API_BASE_URL;

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/folders`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/folders/${id}?include=organization,parent,tags,allowedLocations,files,subfolders,parent,roles,users,subfolders.organization,files.organization,serviceCenters,establishments,cafeterias`,
      options
    )
    .then((response) => {
      let customer = jsona.deserialize(response.data);
      delete customer.links;
      return customer;
    });
}

function add(folder) {
  const payload = jsona.serialize({
    stuff: folder,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(`${url}/folders`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(folder) {
  const payload = jsona.serialize({
    stuff: folder,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/folders/${folder.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/folders/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
};
