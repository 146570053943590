import permissions from "../constants/permissions";
import ResellerModuleLayout from "@/views/Pages/ResellerModule/ResellerModuleLayout.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListResellerInvoicePage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerInvoiceManagement/ListResellerInvoicePage.vue"
  );
const AddResellerInvoicePage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerInvoiceManagement/AddResellerInvoicePage.vue"
  );
const EditResellerInvoicePage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerInvoiceManagement/EditResellerInvoicePage.vue"
  );
const ViewResellerInvoicePage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerInvoiceManagement/ViewResellerInvoicePage.vue"
  );

const ListResellerPaymentPage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerPaymentManagement/ListResellerPaymentPage.vue"
  );
const AddResellerPaymentPage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerPaymentManagement/AddResellerPaymentPage.vue"
  );
const EditResellerPaymentPage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerPaymentManagement/EditResellerPaymentPage.vue"
  );
const ViewResellerPaymentPage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerPaymentManagement/ViewResellerPaymentPage.vue"
  );

const ListResellerProductPage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerProductManagement/ListResellerProductPage.vue"
  );
const AddResellerProductPage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerProductManagement/AddResellerProductPage.vue"
  );
const EditResellerProductPage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerProductManagement/EditResellerProductPage.vue"
  );
const ViewResellerProductPage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerProductManagement/ViewResellerProductPage.vue"
  );

const ListResellerServicePage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerServiceManagement/ListResellerServicePage.vue"
  );
const AddResellerServicePage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerServiceManagement/AddResellerServicePage.vue"
  );
const EditResellerServicePage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerServiceManagement/EditResellerServicePage.vue"
  );
const ViewResellerServicePage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerServiceManagement/ViewResellerServicePage.vue"
  );

const ListPackagePage = () =>
  import("@/views/Pages/ResellerModule/PackageManagement/ListPackagePage.vue");
const AddPackagePage = () =>
  import("@/views/Pages/ResellerModule/PackageManagement/AddPackagePage.vue");
const EditPackagePage = () =>
  import("@/views/Pages/ResellerModule/PackageManagement/EditPackagePage.vue");
const ViewPackagePage = () =>
  import("@/views/Pages/ResellerModule/PackageManagement/ViewPackagePage.vue");

const ListSubscriptionPage = () =>
  import(
    "@/views/Pages/ResellerModule/SubscriptionManagement/ListSubscriptionPage.vue"
  );
const AddSubscriptionPage = () =>
  import(
    "@/views/Pages/ResellerModule/SubscriptionManagement/AddSubscriptionPage.vue"
  );
const EditSubscriptionPage = () =>
  import(
    "@/views/Pages/ResellerModule/SubscriptionManagement/EditSubscriptionPage.vue"
  );
const ViewSubscriptionPage = () =>
  import(
    "@/views/Pages/ResellerModule/SubscriptionManagement/ViewSubscriptionPage.vue"
  );

const ResellerPage = () =>
  import("@/views/Pages/ResellerModule/ResellerPage.vue");

let resellerInvoiceManagementPages = {
  path: "reseller-invoices",
  component: ResellerModuleLayout,
  children: [
    {
      path: "",
      name: "List ResellerInvoices",
      components: { default: ListResellerInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_RESELLER_INVOICES,
      },
    },
    {
      path: "add",
      name: "Add ResellerInvoice",
      components: { default: AddResellerInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_RESELLER_INVOICES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit ResellerInvoice",
      components: { default: EditResellerInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_RESELLER_INVOICES,
      },
    },
    {
      path: "view/:id",
      name: "View ResellerInvoice",
      components: { default: ViewResellerInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_RESELLER_INVOICES,
      },
    },
  ],
};

let resellerPaymentManagementPages = {
  path: "reseller-payments",
  component: ResellerModuleLayout,
  children: [
    {
      path: "",
      name: "List ResellerPayments",
      components: { default: ListResellerPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_RESELLER_PAYMENTS,
      },
    },
    {
      path: "add",
      name: "Add ResellerPayment",
      components: { default: AddResellerPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_RESELLER_PAYMENTS,
      },
    },
    {
      path: "view/:id",
      name: "View ResellerPayment",
      components: { default: ViewResellerPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_RESELLER_PAYMENTS,
      },
    },
  ],
};

let resellerProductsManagementPages = {
  path: "reseller-products",
  component: ResellerModuleLayout,
  children: [
    {
      path: "",
      name: "List ResellerProducts",
      components: { default: ListResellerProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_RESELLER_PRODUCTS,
      },
    },
    {
      path: "add",
      name: "Add ResellerProduct",
      components: { default: AddResellerProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_RESELLER_PRODUCTS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit ResellerProduct",
      components: { default: EditResellerProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_RESELLER_PRODUCTS,
      },
    },
    {
      path: "view/:id",
      name: "View ResellerProduct",
      components: { default: ViewResellerProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_RESELLER_PRODUCTS,
      },
    },
  ],
};

let resellerServicesManagementPages = {
  path: "reseller-services",
  component: ResellerModuleLayout,
  children: [
    {
      path: "",
      name: "List ResellerServices",
      components: { default: ListResellerServicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_RESELLER_SERVICES,
      },
    },
    {
      path: "add",
      name: "Add ResellerService",
      components: { default: AddResellerServicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_RESELLER_SERVICES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit ResellerService",
      components: { default: EditResellerServicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_RESELLER_SERVICES,
      },
    },
    {
      path: "view/:id",
      name: "View ResellerService",
      components: { default: ViewResellerServicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_RESELLER_SERVICES,
      },
    },
  ],
};

let packageManagementPages = {
  path: "packages",
  component: ResellerModuleLayout,
  children: [
    {
      path: "",
      name: "List Packages",
      components: { default: ListPackagePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CUSTOMERS,
      },
    },
    {
      path: "add",
      name: "Add Package",
      components: { default: AddPackagePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CUSTOMERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Package",
      components: { default: EditPackagePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CUSTOMERS,
      },
    },
    {
      path: "view/:id",
      name: "View Package",
      components: { default: ViewPackagePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CUSTOMERS,
      },
    },
  ],
};

let subscriptionManagementPages = {
  path: "subscriptions",
  component: ResellerModuleLayout,
  children: [
    {
      path: "",
      name: "List Subscriptions",
      components: { default: ListSubscriptionPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CUSTOMERS,
      },
    },
    {
      path: "add",
      name: "Add Subscription",
      components: { default: AddSubscriptionPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CUSTOMERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Subscription",
      components: { default: EditSubscriptionPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CUSTOMERS,
      },
    },
    {
      path: "view/:id",
      name: "View Subscription",
      components: { default: ViewSubscriptionPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CUSTOMERS,
      },
    },
  ],
};

const resellerModuleRoutes = [
  {
    path: "",
    component: ResellerModuleLayout,
    children: [
      {
        path: "",
        name: "Reseller Page",
        components: { default: ResellerPage },
        meta: { middleware: authenticated },
      },
    ],
  },
  resellerInvoiceManagementPages,
  resellerPaymentManagementPages,
  resellerProductsManagementPages,
  resellerServicesManagementPages,
  packageManagementPages,
  subscriptionManagementPages,
];

export default resellerModuleRoutes;
