import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

const includes = [
  "organization",
  "recipient",
  "items",
  "items.salesOrderable",
  "allowedLocations",
  "sourceWarehouse",
  "items.taxGroups",
  "items.unitOfMeasureUnit",
  "serviceCenter",
  "establishment",
  "cafeteria",
  "paymentTerm",
];

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/sales-orders`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/sales-orders/${id}?include=${includes.join(",")}`, options)
    .then((response) => {
      let order = jsona.deserialize(response.data);
      delete order.links;
      return order;
    });
}

function add(order) {
  const payload = jsona.serialize({
    stuff: order,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/sales-orders?include=${includes.join(",")}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(order) {
  const payload = jsona.serialize({
    stuff: order,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(
      `${url}/sales-orders/${order.id}?include=${includes.join(",")}`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(
    `${url}/sales-orders/${id}?include=${includes.join(",")}`,
    options
  );
}

function validate(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/sales-orders/${id}/validate?include=${includes.join(",")}`,
      options
    )
    .then((response) => {
      let order = jsona.deserialize(response.data);
      delete order.links;
      return order;
    });
}

function cancel(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/sales-orders/${id}/cancel?include=${includes.join(",")}`,
      options
    )
    .then((response) => {
      let order = jsona.deserialize(response.data);
      delete order.links;
      return order;
    });
}

function generateInvoice({ id, invoicingType, items }) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/sales-orders/${id}/generate-invoice`,
      { invoicingType, items },
      options
    )
    .then((response) => {
      let order = jsona.deserialize(response.data);
      delete order.links;
      return order;
    });
}

function generateDelivery({ id, items }) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/sales-orders/${id}/generate-delivery`, items, options)
    .then((response) => {
      let delivery = jsona.deserialize(response.data);
      delete delivery.links;
      return delivery;
    });
}

function print(id) {
  const options = {
    headers: {},
    responseType: "blob",
  };

  return axios.get(`${url}/sales-orders/${id}/print`, options);
}

function sendMail(data) {
  const payload = {
    ...data,
    id: undefined,
  };

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/sales-orders/${data?.id}/send-mail`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function publicGet({ id, token }) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/public/sales-orders/${id}?token=${token}&include=${includes.join(",")}`, options)
    .then((response) => {
      let order = jsona.deserialize(response.data);
      delete order.links;
      return order;
    });
}


function publicDownloadPdf({ id, token }) {
  const options = {
    headers: {},
    responseType: "blob",
  };

  return axios.get(`${url}/public/sales-orders/${id}/download?token=${token}`, options);
}

function publicValidate({ id, token, validatorInfos }) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/public/sales-orders/${id}/validate?token=${token}&include=${includes.join(",")}`, validatorInfos, options)
    .then((response) => {
      let order = jsona.deserialize(response.data);
      delete order.links;
      return order;
    });
}


function publicCancel({ id, token, validatorInfos }) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/public/sales-orders/${id}/cancel?token=${token}&include=${includes.join(",")}`, validatorInfos, options)
    .then((response) => {
      let order = jsona.deserialize(response.data);
      delete order.links;
      return order;
    });
}


export default {
  list,
  get,
  add,
  update,
  destroy,
  validate,
  cancel,
  generateInvoice,
  generateDelivery,
  print,
  sendMail,
  publicGet,
  publicDownloadPdf,
  publicValidate,
  publicCancel,
};
