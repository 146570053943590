import service from "@/store/services/app-config-service";

export const state = () => ({
  appConfig: null,
  apiDomain: process.env.VUE_APP_API_DOMAIN_URL,
});

export const mutations = {
  SET_RESOURCE: (state, appConfig) => {
    state.appConfig = appConfig;
  },
};

export const actions = {
  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((appConfig) => {
      commit("SET_RESOURCE", appConfig);
    });
  },
};

const getters = {
  appConfig: (state) => state.appConfig,
  apiDomain: (state) => state.apiDomain,
};

const appConfig = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default appConfig;
