import permissions from "../constants/permissions";
import SalesModuleLayout from "@/views/Pages/SalesModule/SalesModuleLayout.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListOrderPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesOrderManagement/ListSalesOrderPage.vue"
  );
const AddOrderPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesOrderManagement/AddSalesOrderPage.vue"
  );
const EditOrderPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesOrderManagement/EditSalesOrderPage.vue"
  );
const ViewOrderPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesOrderManagement/ViewSalesOrderPage.vue"
  );

const ListInvoicePage = () =>
  import(
    "@/views/Pages/SalesModule/SalesInvoiceManagement/ListSalesInvoicePage.vue"
  );
const AddInvoicePage = () =>
  import(
    "@/views/Pages/SalesModule/SalesInvoiceManagement/AddSalesInvoicePage.vue"
  );
const EditInvoicePage = () =>
  import(
    "@/views/Pages/SalesModule/SalesInvoiceManagement/EditSalesInvoicePage.vue"
  );
const ViewInvoicePage = () =>
  import(
    "@/views/Pages/SalesModule/SalesInvoiceManagement/ViewSalesInvoicePage.vue"
  );

const ListDeliveryPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesDeliveryManagement/ListSalesDeliveryPage.vue"
  );
const AddDeliveryPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesDeliveryManagement/AddSalesDeliveryPage.vue"
  );
const EditDeliveryPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesDeliveryManagement/EditSalesDeliveryPage.vue"
  );
const ViewDeliveryPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesDeliveryManagement/ViewSalesDeliveryPage.vue"
  );

const ListPaymentPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesPaymentManagement/ListSalesPaymentPage.vue"
  );
const AddPaymentPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesPaymentManagement/AddSalesPaymentPage.vue"
  );
const EditPaymentPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesPaymentManagement/EditSalesPaymentPage.vue"
  );
const ViewPaymentPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesPaymentManagement/ViewSalesPaymentPage.vue"
  );

const SalesPage = () => import("@/views/Pages/SalesModule/SalesPage.vue");

let orderManagementPages = {
  path: "orders",
  component: SalesModuleLayout,
  children: [
    {
      path: "",
      name: "List SalesOrders",
      components: { default: ListOrderPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SALES_ORDERS,
      },
    },
    {
      path: "add",
      name: "Add SalesOrder",
      components: { default: AddOrderPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_SALES_ORDERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit SalesOrder",
      components: { default: EditOrderPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_SALES_ORDERS,
      },
    },
    {
      path: "view/:id",
      name: "View SalesOrder",
      components: { default: ViewOrderPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_SALES_ORDERS,
      },
    },
  ],
};

let invoiceManagementPages = {
  path: "invoices",
  component: SalesModuleLayout,
  children: [
    {
      path: "",
      name: "List SalesInvoices",
      components: { default: ListInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SALES_INVOICES,
      },
    },
    {
      path: "add",
      name: "Add SalesInvoice",
      components: { default: AddInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_SALES_INVOICES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit SalesInvoice",
      components: { default: EditInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_SALES_INVOICES,
      },
    },
    {
      path: "view/:id",
      name: "View SalesInvoice",
      components: { default: ViewInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_SALES_INVOICES,
      },
    },
  ],
};

let deliveryManagementPages = {
  path: "deliveries",
  component: SalesModuleLayout,
  children: [
    {
      path: "",
      name: "List SalesDeliveries",
      components: { default: ListDeliveryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SALES_DELIVERIES,
      },
    },
    {
      path: "add",
      name: "Add SalesDelivery",
      components: { default: AddDeliveryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_SALES_DELIVERIES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit SalesDelivery",
      components: { default: EditDeliveryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_SALES_DELIVERIES,
      },
    },
    {
      path: "view/:id",
      name: "View SalesDelivery",
      components: { default: ViewDeliveryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_SALES_DELIVERIES,
      },
    },
  ],
};

let paymentManagementPages = {
  path: "payments",
  component: SalesModuleLayout,
  children: [
    {
      path: "",
      name: "List SalesPayments",
      components: { default: ListPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SALES_DELIVERIES,
      },
    },
    {
      path: "add",
      name: "Add SalesPayment",
      components: { default: AddPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_SALES_DELIVERIES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit SalesPayment",
      components: { default: EditPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_SALES_DELIVERIES,
      },
    },
    {
      path: "view/:id",
      name: "View SalesPayment",
      components: { default: ViewPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_SALES_DELIVERIES,
      },
    },
  ],
};

const salesModuleRoutes = [
  {
    path: "",
    component: SalesModuleLayout,
    children: [
      {
        path: "",
        name: "Sales Page",
        components: { default: SalesPage },
        meta: { middleware: authenticated },
      },
    ],
  },
  orderManagementPages,
  invoiceManagementPages,
  deliveryManagementPages,
  paymentManagementPages,
];

export default salesModuleRoutes;
