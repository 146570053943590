<template>
  <router-view></router-view>
</template>

<script>
export default {
  components: {},

  mixins: [],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
