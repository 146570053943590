export default {
  SUPPLY_RULE_NAME: "Nom de la règle",
  SUPPLY_RULE_DESCRIPTION: "Description de la règle",
  SUPPLY_RULES_LIST: "Liste des règles approvisionnement",
  ADD_SUPPLY_RULE: "Ajouter une règle approvisionnement",
  SUPPLY_RULE_ADDED: "Règle d'approvisionnement ajouté",
  EDIT_SUPPLY_RULE: "Modifier une règle d'approvisionnement",
  SUPPLY_RULE_UPDATED: "Règle d'approvisionnement mis à jour",
  DELETE_THIS_SUPPLY_RULE: "Supprimer cette règle d'approvisionnement ?",
  SUPPLY_RULE_DELETED: "Règle d'approvisionnement supprimée",
  SUPPLY_RULE_EXCERPT: "Note sur la règle d'approvisionnement",
  EXCERPT: "Note",
  WEBSITE_URL: "Site web",
  SUPPLY_RULE_MIN_QUANTITY: "Achat Minimal",
  SUPPLY_RULE_MAX_QUANTITY: "Achat Maximal",
  SUPPLY_RULE_ORDER_DELAY: "Délai de livraison (jours)",
  SUPPLY_RULE_ORDER_METHOD: "Méthode",
  SUPPLY_RULE_ORDER_BEFORE_HOUR: "Commander avant",
  SUPPLY_RULE_ORDER_BEFORE_DATE: "Commander avant",
  SUPPLY_RULE_DELIVERY_DATES: "Date de livraison",
  SUPPLY_RULE_DELIVERY_DAYS: "Jrs de livraison",
  SUPPLY_RULE_ORDER_DAYS: "Jrs de commande",
  SUPPLY_RULE_DELIVERY_DAY_0: "Dimanche",
  SUPPLY_RULE_DELIVERY_DAY_1: "Lundi",
  SUPPLY_RULE_DELIVERY_DAY_2: "Mardi",
  SUPPLY_RULE_DELIVERY_DAY_3: "Mercredi",
  SUPPLY_RULE_DELIVERY_DAY_4: "Jeudi",
  SUPPLY_RULE_DELIVERY_DAY_5: "Vendredi",
  SUPPLY_RULE_DELIVERY_DAY_6: "Samedi",
  SUPPLY_RULE_ORDER_METHOD_SMS: "SMS",
  SUPPLY_RULE_ORDER_METHOD_EMAIL: "Courriel",
  SUPPLY_RULE_ORDER_METHOD_PHONE: "Téléphone",
  SUPPLY_RULE_ORDER_METHOD_ONLINE_ORDER: "Commande en ligne",
  SUPPLY_RULE_ORDER_METHOD_INTERNAL: "Interne",
  SUPPLY_RULE_DELIVERY_DATE: "Date de livraison",
  SUPPLY_RULE_ORDER_DATE: "Date de commande",
  SUPPLY_RULE_DATE_EXPIRED: "Date dépassée",
  DELIVERY_AVAILABLE_ONLY: "Livraison disponible seulement",
  DELIVERY_TIME_EXCEEDED: "Délai de livraison dépassé",
  SUPPLY_RULE_HOUR_EXPIRED: "Heure de commande dépassée",
  SUPPLY_RULE_INVALID_ORDER_DATE: "Date de commande invalide",
};
