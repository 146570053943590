export default {
  PURCHASES_INVOICES_LIST: "Liste des factures",
  ADD_PURCHASES_INVOICE: "Ajouter une facture",
  ADD_PURCHASES_INVOICE_ITEM: "Ajouter une ligne",
  PURCHASES_INVOICE_ADDED: "Facture ajoutée",
  OWNER: "Propriétaire",
  EDIT_PURCHASES_INVOICE: "Modifier une facture",
  PURCHASES_INVOICE_UPDATED: "Facture mise à jour",
  BILLING_ADDRESS: "Adresse de facturation",
  EXPIRATION_TIME: "Date d'expiration",
  ITEM_TYPE: "Type",
  SELECT_PRODUCTS: "Choisir un produit",
  SELECT_SUBSCRIPTIONS: "Choisir une inscription",
  SELECT_PACKAGES: "Choisir un forfait",
  DELETE_PURCHASES_INVOICE_ITEM: "Supprimer la ligne",
  UPDATE_PURCHASES_INVOICE_ITEM: "Modifier la ligne",
  PURCHASES_INVOICE_ITEM_DELETED: "Ligne supprimée",
  PURCHASES_INVOICE_ITEM_ADDED: "Ligne ajoutée",
  PURCHASES_INVOICE_ITEM_EDITED: "Ligne modifiée",
  SEND: "Envoyer",
  CANCEL: "Annuler",
  REFUND: "Rembourser",
  ADD_PAYMENT: "Ajouter un paiement",
  PURCHASES_INVOICE_SENT: "Facture envoyée",
  SELECT_USERS: "Choisir l'utilisateur",
  SELECT_CUSTOMERS: "Choisir le client",
  SELECT_ORGANIZATIONS: "Choisir l'organization",
  SELECT_SUPPLIERS: "Choisir le fournisseur",
  DELETE_THIS_PURCHASES_INVOICE: "Supprimer cette facture ?",
  PURCHASES_INVOICE_DELETED: "Facture supprimée",
  CANCEL_PURCHASES_INVOICE_QUESTION: "Annuler la facture ?",
  PURCHASES_INVOICE_CANCELED: "Facture annulée",
  RECIPIENT: "Destinataire",
  ISSUER: "Emetteur",
  VALIDATE: "Valider",
  PURCHASES_INVOICE_VALIDATED: "Facture validée",
  VIEW_ORDER: "Voir la commande",
  EXCLUDING_TAXES: "Facture sans taxes",
};
