<template>
  <div class="main-content">
    <zoom-center-transition :duration="200" mode="out-in">
      <router-view></router-view>
    </zoom-center-transition>
  </div>
</template>

<script>
import { ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    ZoomCenterTransition,
  },

  props: {},

  data() {
    return {};
  },

  computed: {},

  methods: {},
};
</script>
