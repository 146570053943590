<template>
  <div>
    <span v-for="(supplier, idx) in suppliers" :key="supplier.id">
      <router-link :to="{ name: 'View Supplier', params: { id: supplier.id } }">
        {{ `${supplier.company_name}` }}
      </router-link>
      {{ suppliers?.length - 1 > idx ? "," : " " }}<br />
    </span>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "suppliers",

  components: {},

  mixins: [formMixin],

  props: {
    suppliers: {
      type: Array,
      default: () => [],
      description: "Suppliers",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    suppliers(suppliers) {},
  },
};
</script>
