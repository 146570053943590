<template>
  <div>
    <span v-for="(product, idx) in products" :key="product.id">
      <router-link :to="{ name: 'View Product', params: { id: product.id } }">
        {{ `${product.name}` }}
      </router-link>
      {{ products.length - 1 > idx ? "," : " " }}<br />
    </span>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "products",

  components: {},

  mixins: [formMixin],

  props: {
    products: {
      type: Array,
      default: () => [],
      description: "Products",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    products(products) {},
  },
};
</script>
