import store from "../store";
import moment from "moment";
import 'moment-timezone';

const timeZoneDateFormat = {
  install: (Vue) => {
    Vue.prototype.$timeZoneDateFormat = (value, format = "LLL") => {
      const me = store.getters["profile/me"];
      const momentDate = moment.tz(value, "UTC").tz(moment.tz.guess());
      return momentDate.locale(me?.locale ?? "fr").format(format);
    };
    Vue.prototype.$getUtcDateTimeString = (value) => {
      return moment.tz(value, moment.tz.guess()).tz("UTC").format();
    };
    Vue.prototype.$getTimeZone = () => {
      return moment.tz.guess();
    };
    Vue.prototype.$getTimeZonetOffset = () => {
      return moment.tz(moment.tz.guess()).utcOffset() / 60;
    };
    Vue.prototype.$getTimeZoneDuration = () => {
      const offsetHours = Math.abs(moment.tz(moment.tz.guess()).utcOffset() / 60);
      const duration = moment.duration(offsetHours, "hours");
      return moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
    };
    Vue.prototype.$momentTimeZoneDate = (value) => {
      const momentDate = moment.tz(value, "UTC").tz(moment.tz.guess());
      return momentDate;
    };
  },
};

export default timeZoneDateFormat;
