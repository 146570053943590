export default {
  CATEGORIES_LIST: "Liste des catégories",
  ADD_CATEGORY: "Ajouter une catégorie",
  CATEGORY_ADDED: "Catégorie ajoutée",
  EDIT_CATEGORY: "Modifier une catégorie",
  CATEGORY_UPDATED: "Catégorie mis à jour",
  SUB_CATEGORY: "Sous catégorie",
  DELETE_THIS_CATEGORY: "Supprimer cette catégorie ?",
  CATEGORY_DELETED: "Catégorie supprimée",
  COLOR: "Couleur",
  ICON: "Icon",
  PARENT_CATEGORY: "Catégorie parente",
  IN_SUB_CATEGORY: "Sous catégorie",
  HIERARCHICAL_CATEGORY: "Hiérarchique",
};
