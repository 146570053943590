export default {
  MODULE: "Module",
  NAME: "Nom",
  VALUE: "Valeur",
  MODULE_GENERAL: "Général",
  MODULE_PURCHASES: "Achats",
  MODULE_SALES: "Ventes",
  OPTION_DOCUMENT_FOOTER: "Pied de page des document",
  OPTION_DEFAULT_TAXES_GROUP: "Groupe de taxes par défaut",
  OPTION_FORCE_MODEL_USAGE: "Forcer l'utilisation des modèles",
  OPTION_DEFAULT_SALES_ORDER_TERMS: "Termes et conditions soumission",
  OPTION_DEFAULT_SALES_ORDER_DESCRIPTION: "Description soumission",
  OPTION_DEFAULT_SALES_INVOICE_TERMS: "Termes et conditions facture vente",
  OPTION_DEFAULT_SALES_INVOICE_DESCRIPTION: "Description facture vente",
  OPTION_DEFAULT_PURCHASES_ORDER_TERMS: "Termes et conditions commande achat",
  OPTION_DEFAULT_PURCHASES_ORDER_DESCRIPTION: "Description commande achat",
  OPTION_DEFAULT_PURCHASES_INVOICE_TERMS: "Termes et conditions facture achat",
  OPTION_DEFAULT_PURCHASES_INVOICE_DESCRIPTION: "Description facture achat",
  OPTION_DEFAULT_PURCHASES_PAYMENT_TERMS: "Terme de paiement achats par défaut",
  OPTION_DEFAULT_SALES_PAYMENT_TERMS: "Terme de paiement ventes par défaut",
};
