import store from "../store";

const fillUserCafeteriasData = {
  install: (Vue) => {
    Vue.prototype.$fillUserCafeteriasData = (object) => {
      const me = store.getters["profile/me"];
      if (me.restrict_to_service_centers) {
        if (me.allowedServiceCenters.length > 0) {
          object.serviceCenter = {
            type: "service-centers",
            id: me.allowedServiceCenters[0].id,
          };
        }
      }
      if (me.restrict_to_establishments) {
        if (me.allowedEstablishments.length > 0) {
          object.establishment = {
            type: "establishments",
            id: me.allowedEstablishments[0].id,
          };
        }
      }
      if (me.restrict_to_cafeterias) {
        if (me.allowedCafeterias.length > 0) {
          object.cafeteria = {
            type: "cafeterias",
            id: me.allowedCafeterias[0].id,
          };
        }
      }
      return object;
    };
  },
};

export default fillUserCafeteriasData;
