export default {
  CASH_REGISTER_READINGS_LIST: "Liste ventes",
  ADD_CASH_REGISTER_READING_ITEMS: "Ajouter une vente",
  CASH_REGISTER_READING_ADDED: "Vente ajoutée",
  EDIT_CASH_REGISTER_READING: "Modifier la vente",
  CASH_REGISTER_READING_UPDATED: "Vente mise à jour",
  DELETE_THIS_CASH_REGISTER_READING: "Supprimer cette Vente ?",
  CASH_REGISTER_READING_DELETED: "Vente supprimée",
  STARTS_AT: "Début",
  ENDS_AT: "Fin",
  CREATED_BY: "Crée par",
  UPDATED_BY: "Modifieé par",
  UPDATED_BY: "Modifieé par",
  TOTAL_AMOUNT: "Montant total",
  TITLE: "Titre",
  CASH_REGISTER_CATEGORY_PEDAGO: "Pédagogie",
  EVENT_CATEGORY_THEMATIC_MEAL: "Repas Thématique",
  EVENT_CATEGORY_TASTING: "Dégustation",
  EVENT_CATEGORY_PROMOTION: "Promotion",
  EVENT_CATEGORY_EVENT: "Événement",
  EVENT_CATEGORY_INTERNAL_JOURNAL: "Journal Interne",
  EVENT_CATEGORY_CLIENT_JOURNAL: "Journal Client",
  EVENT_CATEGORY_SURVEY: "Sondage",
  EVENT_CATEGORY_MONTHLY_INSPIRATION: "Inspiration du Mois",
  EVENT_CATEGORY_OPEN_HOUSE: "Porte-Ouverte",
  SHOW_MY_EVENTS: "Voir les caisses qui me concernent",
  SHOW_ALL: "Voir tous les caisses",
 
 
};
