import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/cash-register-reading-payment-items`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/cash-register-reading-payment-items/${id}?include=cashRegister`,
      options
    )
    .then((response) => {
      let customer = jsona.deserialize(response.data);
      delete customer.links;
      return customer;
    });
}

function add(event) {
  const payload = jsona.serialize({
    stuff: event,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/cash-register-reading-payment-items?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(event) {
  const payload = jsona.serialize({
    stuff: event,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };
console.log(event)
  return axios
    .patch(`${url}/cash-register-reading-payment-items/${event.id}?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/cash-register-reading-payment-items/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
};
