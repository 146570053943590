import service from "@/store/services/supplier-products-service";

export const state = () => ({
  list: {},
  supplierProduct: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, supplierProduct) => {
    state.supplierProduct = supplierProduct;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((supplierProduct) => {
      commit("SET_RESOURCE", supplierProduct);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((supplierProduct) => {
      commit("SET_RESOURCE", supplierProduct);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((supplierProduct) => {
      commit("SET_RESOURCE", supplierProduct);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  supplierProduct: (state) => state.supplierProduct,
  url: (state) => state.url,
};

const supplierProducts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default supplierProducts;
