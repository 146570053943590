<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_RESELLER_INVOICES)
          "
          true
          :link="{
            name: $t('COMMON.RESELLER_INVOICES'),
            icon: 'ni ni-single-copy-04 text-primary',
            path: '/reseller/reseller-invoices',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_RESELLER_PAYMENTS)
          "
          true
          :link="{
            name: $t('COMMON.RESELLER_PAYMENTS'),
            icon: 'ni ni-money-coins text-primary',
            path: '/reseller/reseller-payments',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SUBSCRIPTIONS)"
          true
          :link="{
            name: $t('COMMON.SUBSCRIPTIONS'),
            icon: 'fa fa-id-card text-primary',
            path: '/reseller/subscriptions',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_RESELLER_PRODUCTS)
          "
          true
          :link="{
            name: $t('COMMON.RESELLER_PRODUCTS'),
            icon: 'fa fa-cubes text-primary',
            path: '/reseller/reseller-products',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_RESELLER_SERVICES)
          "
          true
          :link="{
            name: $t('COMMON.RESELLER_SERVICES'),
            icon: 'fa fa-cube text-primary',
            path: '/reseller/reseller-services',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PACKAGES)"
          true
          :link="{
            name: $t('COMMON.PACKAGES'),
            icon: 'ni ni-box-2 text-primary',
            path: '/reseller/packages',
          }"
        >
        </sidebar-item>

        <hr
          class="my-3"
          style="
            border-color: rgba(0, 0, 0, 0.1) currentcolor currentcolor;
            border-style: solid none none;
            border-width: 1px 0px 0px;
            border-image: none 100% / 1 / 0 stretch;
            min-width: 80%;
            overflow: visible;
            box-sizing: content-box;
            height: 0px;
          "
        />

        <lang-switcher />
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar
        :type="$route.meta.navbarType"
        :appModuleIcon="'ni ni-2x ni-settings-gear-65'"
        :appModuleName="$t('APPS.RESELLER_MODULE')"
      ></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";
import { LangSwitcher } from "@/components";
import DashboardNavbar from "../../Layouts/Authenticated/DashboardNavbar.vue";
import ContentFooter from "../../Layouts/Authenticated/ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    LangSwitcher,
  },
  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
