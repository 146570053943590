<template>
  <span
    class="mx-2"
    v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CATEGORIES) && category"
  >
    <router-link :to="{ name: 'View Category', params: { id: category.id } }">
      {{ `${category.name}` }}
    </router-link>
  </span>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "category",

  components: {},

  mixins: [formMixin],

  props: {
    category: {
      type: Object,
      default: () => ({}),
      description: "Organization",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    category(category) {},
  },
};
</script>
