export default {
  IMPORTS_LIST: "Liste des imports",
  IMPORTS_PREVIEW: "Voir un aperçu",
  ADD_IMPORT: "Ajouter un import",
  IMPORT_ADDED: "Imports ajoutée",
  EDIT_IMPORT: "Modifier un import",
  IMPORT_UPDATED: "Imports mise à jour",
  DELETE_THIS_IMPORT: "Supprimer ce import ?",
  IMPORT_DELETED: "Imports supprimé",
  IMPORT_MODEL: "Modèle",
  CHOOSE_MODEL: "Choisissez un modèle d'import",
  IMPORT_NAME: "Nom",
  IMPORT_FILE_SIZE: "Taille du fichier",
  COLUMN_ROW_COUNT: "Lignes d'import",
  SHOW_ROWS: "Données de l'import",
  COUNT_CSV_ROWS: "Nombre de lignes",
  IMPORT_PREVIEW_DATA: "Aperçu des données à importer",
  CSV_PREVIEW_DATA: "Aperçu des données du fichier",
  FILE: "Fichier",
  RUN_DRY_IMPORT: "Tester l'import",
  RUN_IMPORT: "Lancer l'import",
  RESULTS_ON_X_LINES: "Résultats pour un total de {linesCount} lignes",
  SUCCESSES: "Succès",
  FAILURES: "Échecs",
  X_ROW: "Ligne {line}",
  LINE: "Ligne",
  DATA: "Données",
  ERROR: "Erreur",
  MODEL_ID: "ID",
  RESULTS: "Résultats",
  IMPORTED_ITEMS: "Objets importés",
  ITEM: "Objet",
  MODEL_FIELD: "Champ du modèle",
  STATUS: "STATUT",
  UPDATED: "Mise à jour",
  CREATED: "Nouveau",
  NEW: "Nouveau",
  DELETED: "Supprimé",
  CSV_FIELD: "Champ du fichier csv",
  ADD_SYNCHRONIZATION: "Ajouter une synchronisation",
  IDENTIFIER: "Identificateur",
  RUN_DRY_SYNC: "Tester la synchronisation",
  IDENTIFIERS: "Identifiants",
  IDENTIFIERS_EXPLAIN:
    "Ces champs sont des identifiants qui seront utilisés pour idenfitier chaque ligne du fichier csv",
};
