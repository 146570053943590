<template>
  <div>
    <span v-for="(warehouse, idx) in warehouses" :key="warehouse.id">
      <router-link
        :to="{ name: 'View Warehouse', params: { id: warehouse.id } }"
      >
        {{ `${warehouse.name}` }}
      </router-link>
      {{ warehouses.length - 1 > idx ? "," : " " }}<br />
    </span>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "warehouses",

  components: {},

  mixins: [formMixin],

  props: {
    warehouses: {
      type: Array,
      default: () => [],
      description: "Warehouses",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    warehouses(warehouses) {},
  },
};
</script>
