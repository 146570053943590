<template>
  <div>
    <router-link :to="{ name: 'View Warehouse', params: { id: warehouse.id } }">
      {{ `${warehouse.name}` }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "warehouse",

  components: {},

  mixins: [],

  props: {
    warehouse: {
      type: Object,
      required: true,
      description: "Warehouse",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {},
};
</script>
