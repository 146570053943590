import store from "../store";
import currentUserCan from "../helpers/currentUserCan";

export default async function admin({ next, router, from, to }) {
  try {
    let me = store.getters["profile/me"];
    if (!me) {
      if (store.getters["isAuthenticated"]) {
        await store.dispatch("profile/me");
        me = store.getters["profile/me"];
      }
    }

    if (!currentUserCan(me, to.meta.permission)) {
      router.app.$notify({
        type: "danger",
        message: router.app.$t("ERRORS.PAGE_NOT_ALLOWED"),
      });
      if (from.path !== "/dashboard") {
        return router.push({ path: "/" });
      } else {
        return;
      }
    }
    return next();
  } catch (e) {
    if (e.response) {
      if ([401, 403].includes(e.response.status)) {
        store.dispatch("logout");
      }
    } else {
      throw e;
    }
  }
}
