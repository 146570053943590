import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/supply-rules`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function widgetList(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios
    .get(`${url}/supply-rules/calculates/orders-delivery-dates`, options)
    .then((response) => {
      return {
        list: response.data?.data,
        //list: jsona.deserialize(response.data),
        meta: response.data?.meta,
      };
    });
}

function get({ id, hasFallback = false }) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/supply-rules/${id}?include=organization,allowedLocations,supplier,warehouse${
        hasFallback ? "&no_fallback=true" : ""
      }`,
      options
    )
    .then((response) => {
      let customer = jsona.deserialize(response.data);
      delete customer.links;
      return customer;
    });
}

function add(supplyRule) {
  const payload = jsona.serialize({
    stuff: supplyRule,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/supply-rules?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(supplyRule) {
  const payload = jsona.serialize({
    stuff: supplyRule,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/supply-rules/${supplyRule.id}?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/supply-rules/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  widgetList,
};
