import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbarFn(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbarFn(className);
    }, 100);
  }
}

export default {
  data() {
    return {};
  },
  async mounted() {
    this.initScrollbar();
    this.loadCurrentUserLocale();
  },

  methods: {
    async loadCurrentUserLocale() {
      try {
        const me = await this.$store.getters["profile/me"];
        if (this.$i18n.locale !== me.locale) {
          loadLanguageAsync(me.locale);
        }
      } catch (e) {
        if (e.response) {
          if ([401, 403].includes(e.response.status)) {
            this.$store.dispatch("logout");
          }
        } else {
          throw e;
        }
      }
    },

    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbarFn("sidenav");
      }
    },
  },
};
