import permissions from "../constants/permissions";
import PurchasesModuleLayout from "@/views/Pages/PurchasesModule/PurchasesModuleLayout.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListOrderPage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesOrderManagement/ListPurchasesOrderPage.vue"
  );
const AddOrderPage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesOrderManagement/AddPurchasesOrderPage.vue"
  );
const EditOrderPage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesOrderManagement/EditPurchasesOrderPage.vue"
  );
const ViewOrderPage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesOrderManagement/ViewPurchasesOrderPage.vue"
  );

const ListInvoicePage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesInvoiceManagement/ListPurchasesInvoicePage.vue"
  );
const AddInvoicePage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesInvoiceManagement/AddPurchasesInvoicePage.vue"
  );
const EditInvoicePage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesInvoiceManagement/EditPurchasesInvoicePage.vue"
  );
const ViewInvoicePage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesInvoiceManagement/ViewPurchasesInvoicePage.vue"
  );

const ListDeliveryPage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesDeliveryManagement/ListPurchasesDeliveryPage.vue"
  );
const AddDeliveryPage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesDeliveryManagement/AddPurchasesDeliveryPage.vue"
  );
const EditDeliveryPage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesDeliveryManagement/EditPurchasesDeliveryPage.vue"
  );
const ViewDeliveryPage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesDeliveryManagement/ViewPurchasesDeliveryPage.vue"
  );

const ListPaymentPage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesPaymentManagement/ListPurchasesPaymentPage.vue"
  );
const AddPaymentPage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesPaymentManagement/AddPurchasesPaymentPage.vue"
  );
const EditPaymentPage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesPaymentManagement/EditPurchasesPaymentPage.vue"
  );
const ViewPaymentPage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesPaymentManagement/ViewPurchasesPaymentPage.vue"
  );

const ListSupplierPage = () =>
  import(
    "@/views/Pages/PurchasesModule/SupplierManagement/ListSupplierPage.vue"
  );
const AddSupplierPage = () =>
  import(
    "@/views/Pages/PurchasesModule/SupplierManagement/AddSupplierPage.vue"
  );
const EditSupplierPage = () =>
  import(
    "@/views/Pages/PurchasesModule/SupplierManagement/EditSupplierPage.vue"
  );
const ViewSupplierPage = () =>
  import(
    "@/views/Pages/PurchasesModule/SupplierManagement/ViewSupplierPage.vue"
  );

const PurchasesPage = () =>
  import("@/views/Pages/PurchasesModule/PurchasesPage.vue");

let orderManagementPages = {
  path: "orders",
  component: PurchasesModuleLayout,
  children: [
    {
      path: "",
      name: "List PurchasesOrders",
      components: { default: ListOrderPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PURCHASES_ORDERS,
      },
    },
    {
      path: "add",
      name: "Add PurchasesOrder",
      components: { default: AddOrderPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_PURCHASES_ORDERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit PurchasesOrder",
      components: { default: EditOrderPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_PURCHASES_ORDERS,
      },
    },
    {
      path: "view/:id",
      name: "View PurchasesOrder",
      components: { default: ViewOrderPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_PURCHASES_ORDERS,
      },
    },
  ],
};

let invoiceManagementPages = {
  path: "invoices",
  component: PurchasesModuleLayout,
  children: [
    {
      path: "",
      name: "List PurchasesInvoices",
      components: { default: ListInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PURCHASES_INVOICES,
      },
    },
    {
      path: "add",
      name: "Add PurchasesInvoice",
      components: { default: AddInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_PURCHASES_INVOICES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit PurchasesInvoice",
      components: { default: EditInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_PURCHASES_INVOICES,
      },
    },
    {
      path: "view/:id",
      name: "View PurchasesInvoice",
      components: { default: ViewInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_PURCHASES_INVOICES,
      },
    },
  ],
};

let deliveryManagementPages = {
  path: "deliveries",
  component: PurchasesModuleLayout,
  children: [
    {
      path: "",
      name: "List PurchasesDeliveries",
      components: { default: ListDeliveryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PURCHASES_DELIVERIES,
      },
    },
    {
      path: "add",
      name: "Add PurchasesDelivery",
      components: { default: AddDeliveryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_PURCHASES_DELIVERIES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit PurchasesDelivery",
      components: { default: EditDeliveryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_PURCHASES_DELIVERIES,
      },
    },
    {
      path: "view/:id",
      name: "View PurchasesDelivery",
      components: { default: ViewDeliveryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_PURCHASES_DELIVERIES,
      },
    },
  ],
};

let paymentManagementPages = {
  path: "payments",
  component: PurchasesModuleLayout,
  children: [
    {
      path: "",
      name: "List PurchasesPayments",
      components: { default: ListPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PURCHASES_DELIVERIES,
      },
    },
    {
      path: "add",
      name: "Add PurchasesPayment",
      components: { default: AddPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_PURCHASES_DELIVERIES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit PurchasesPayment",
      components: { default: EditPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_PURCHASES_DELIVERIES,
      },
    },
    {
      path: "view/:id",
      name: "View PurchasesPayment",
      components: { default: ViewPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_PURCHASES_DELIVERIES,
      },
    },
  ],
};

let suppliersManagementPages = {
  path: "suppliers",
  component: PurchasesModuleLayout,
  children: [
    {
      path: "",
      name: "List Suppliers",
      components: { default: ListSupplierPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SUPPLIERS,
      },
    },
    {
      path: "add",
      name: "Add Supplier",
      components: { default: AddSupplierPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_SUPPLIERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Supplier",
      components: { default: EditSupplierPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_SUPPLIERS,
      },
    },
    {
      path: "view/:id",
      name: "View Supplier",
      components: { default: ViewSupplierPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_SUPPLIERS,
      },
    },
  ],
};

const purchasesModuleRoutes = [
  {
    path: "",
    component: PurchasesModuleLayout,
    children: [
      {
        path: "",
        name: "Purchases Page",
        components: { default: PurchasesPage },
        meta: { middleware: authenticated },
      },
    ],
  },
  orderManagementPages,
  invoiceManagementPages,
  deliveryManagementPages,
  paymentManagementPages,
  suppliersManagementPages,
];

export default purchasesModuleRoutes;
