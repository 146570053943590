const stateName = {
  install: (Vue) => {
    Vue.prototype.$stateName = (value) => {
      if (value.toLowerCase() == "quebec") {
        return "Québec";
      }
      return value;
    };
  },
};

export default stateName;
