const files = {
  install: (Vue) => {
    Vue.prototype.$isFileVideo = (file) => {
      return ["mp4", "mov", "avi", "mkv", "wmv", "flv"].includes(file.file_extension?.toLowerCase());
    };

    Vue.prototype.$isFileImage = (file) => {
      return ["jpg", "jpeg", "png", "gif", "bmp", "svg"].includes(file.file_extension?.toLowerCase());
    };

    Vue.prototype.$isFileAudio = (file) => {
      return ["mp3", "wav", "aac", "flac", "ogg", "wma"].includes(file.file_extension?.toLowerCase());
    };

    Vue.prototype.$isFileDocument = (file) => {
      return ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "txt"].includes(file.file_extension?.toLowerCase());
    };
  },
};

export default files;