import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

const includes = [
  "organization",
  "issuer",
  "items",
  "items.purchasesDeliverable",
  "items.unitOfMeasureUnit",
  "allowedLocations",
  "purchasesOrder",
  "destinationWarehouse",
  "serviceCenter",
  "establishment",
  "cafeteria",
  "validatedBy",
];

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/purchases-deliveries`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/purchases-deliveries/${id}?include=${includes.join(",")}`,
      options
    )
    .then((response) => {
      let delivery = jsona.deserialize(response.data);
      delete delivery.links;
      return delivery;
    });
}

function add(delivery) {
  const payload = jsona.serialize({
    stuff: delivery,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/purchases-deliveries?include=${includes.join(",")}`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(delivery) {
  const payload = jsona.serialize({
    stuff: delivery,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(
      `${url}/purchases-deliveries/${delivery.id}?include=${includes.join(
        ","
      )}`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(
    `${url}/purchases-deliveries/${id}?include=${includes.join(",")}`,
    options
  );
}

function validate(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/purchases-deliveries/${id}/validate?include=${includes.join(
        ","
      )}`,
      options
    )
    .then((response) => {
      let delivery = jsona.deserialize(response.data);
      delete delivery.links;
      return delivery;
    });
}

function cancel(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/purchases-deliveries/${id}/cancel?include=${includes.join(",")}`,
      options
    )
    .then((response) => {
      let delivery = jsona.deserialize(response.data);
      delete delivery.links;
      return delivery;
    });
}

function print(id) {
  const options = {
    headers: {},
    responseType: "blob",
  };
  return axios.get(`${url}/purchases-deliveries/${id}/print`, options);
}

function sendMail(data) {
  const payload = {
    ...data,
    id: undefined,
  };

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/purchases-deliveries/${data?.id}/send-mail`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  validate,
  cancel,
  print,
  sendMail,
};
