<template>
  <div v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SUPPLIERS) && supplier">
    <router-link :to="{ name: 'View Supplier', params: { id: supplier.id } }">
      {{ `${supplier.company_name}` }}
    </router-link>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "supplier",

  components: {},

  mixins: [formMixin],

  props: {
    supplier: {
      type: Object,
      default: () => ({}),
      description: "Organization",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    supplier(supplier) {},
  },
};
</script>
