<template>
  <!-- NOTIFICATIONS -->
  <base-dropdown
    class="nav-item navbar-notifications"
    tag="li"
    title-classes="nav-link"
    title-tag="a"
    icon="ni ni-bell-55 ni-2x"
    menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
  >
    <template slot="badge" v-if="total > 0">
      <span
        class="badge badge-md badge-circle badge-floating badge-danger border-white"
      >
        {{ total }}
      </span>
    </template>
    <template>
      <!-- Dropdown header -->
      <div class="px-3 py-3">
        <h6
          class="text-sm text-muted m-0"
          v-html="
            $t('NOTIFICATIONS.YOU_HAVE_X_UNREAD_NOTIFICATIONS', {
              count: total,
            })
          "
        ></h6>
      </div>
      <!-- List group -->
      <div class="list-group list-group-flush">
        <a
          href=""
          class="list-group-item list-group-item-action"
          v-for="notification in notifications"
          :key="notification.id"
          @click.prevent="readNotification(notification)"
        >
          <div class="row align-items-center">
            <div class="col-auto">
              <!-- Avatar -->
              <i class="ni ni-bell-55" />
            </div>
            <div class="col ml--2">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h4 class="mb-0 text-sm">
                    {{ getNotificationObject(notification) }}
                  </h4>
                </div>
                <div class="text-right text-muted">
                  <small>
                    {{ getNotificationDuration(notification) }}
                  </small>
                </div>
              </div>
              <p
                class="text-md mb-0"
                v-html="getNotificationDescription(notification)"
              ></p>
            </div>
          </div>
        </a>
      </div>
      <!-- View all -->
      <button
        @click.prevent="viewAllNotifications"
        class="dropdown-item text-center text-primary font-weight-bold py-3"
      >
        {{ $t("NOTIFICATIONS.VIEW_ALL") }}
      </button>
    </template>
  </base-dropdown>
</template>

<script>
import swal from "sweetalert2";
import { mapState } from "vuex";
import { isEqual } from "lodash";

export default {
  components: {},

  props: {},

  async created() {
    this.getNotifications();

    this.unwatch = this.$store.watch(
      (state, getters) => getters["notifications/refreshKey"],
      (newValue, oldValue) => {
        if (newValue > oldValue) {
          this.getNotifications();
        }
      }
    );
  },

  destroyed() {
    clearTimeout(this.notificationTimeout);
  },

  computed: mapState(["notifications/list"]),

  data() {
    return {
      total: 0,
      notifications: [],
      loading: true,
      notificationTimeout: null,
      unwatch: null,
    };
  },

  methods: {
    getNotificationDuration(notification) {
      const duration = this.$moment
        .duration(this.$moment().diff(this.$moment(notification.created_at)))
        .humanize();

      return this.$t("NOTIFICATIONS.X_AGO", {
        duration: duration,
      });
    },

    getNotificationObject(notification) {
      const model = notification.data.object_class.split("\\").pop();
      return this.$t(`COMMON.${model.toUpperCase()}`);
    },

    getNotificationDescription(notification) {
      const causer = `${notification.data.causer.firstname} ${notification.data.causer.lastname}`;
      const event = this.$t(
        `NOTIFICATIONS.DESCRIPTION_EVENT_${notification.data.event}`
      );
      const objectName = notification.data.object_name;

      return this.$t("NOTIFICATIONS.XCAUSER_HAS_XEVENT_XOBJECTNAME", {
        causer: causer,
        event: event,
        objectName: objectName,
      });
    },

    async getNotifications() {
      clearTimeout(this.notificationTimeout);
      try {
        let params = {
          ...(this.created_at ? { sort: this.created_at } : {}),
          filter: { unRead: true },
          page: {
            number: 1,
            size: 10,
          },
          include: "notifiable",
        };
        await this.$store.dispatch("notifications/list", params);
        this.notifications = this.$store.getters["notifications/list"];
        this.total = this.$store.getters["notifications/listTotal"];
        this.loading = false;
        this.notificationTimeout = setTimeout(() => {
          this.getNotifications();
        }, 60000);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async readNotification(notification) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      try {
        await this.$store.dispatch("notifications/get", notification.id);
        this.$router
          .push(
            this.$objectViewRoute({
              type: notification.data.object_type,
              id: notification.data.object_id,
            })
          )
          .catch(() => {});
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
      swal.close();
    },

    viewAllNotifications() {
      this.$router
        .push({
          name: "List Notifications",
        })
        .catch(() => {});
    },
  },
};
</script>
<style>
.navbar-notifications
  span.badge.badge-md.badge-circle.badge-floating.badge-danger.border-white {
  position: absolute;
  left: 10px;
  top: -5px;
}
</style>
