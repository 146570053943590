<template>
  <div>
    <router-view v-if="!appLoading"></router-view>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME,
      meta: (function () {
        return [
          { charset: "utf-8" },
          {
            "http-equiv": "X-UA-Compatible",
            content: "IE=edge,chrome=1",
          },
          {
            name: "viewport",
            content:
              "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
          },
          {
            name: "keywords",
            content: process.env.VUE_APP_NAME,
          },
          {
            name: "description",
            content: process.env.VUE_APP_NAME,
          },
        ];
      })(),

      link: [
        { rel: "icon", type: "image/png", href: "/favicon.png" },
        {
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700",
        },
        {
          rel: "stylesheet",
          href: "https://use.fontawesome.com/releases/v5.6.3/css/all.css",
          integrity:
            "sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/",
          crossorigin: "anonymous",
        },
      ],

      script: (function () {
        if (process.env.VUE_APP_IS_DEMO == 1) {
          return [];
        }
      })(),
    };
  },

  computed: {
    ...mapGetters({
      appConfig: "appConfig/appConfig",
    }),
  },

  data() {
    return {
      appLoading: true,
    };
  },

  async mounted() {
    swal.fire({
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        swal.showLoading();
      },
    });

    await this.$store.dispatch("appConfig/get");

    if (this.$store.getters["isAuthenticated"]) {
      await this.$store.dispatch("profile/me");
      await this.$store.dispatch("organizationOptions/get");
    } else {
      console.log('NOT LOGGED');
    }

    this.appLoading = false;
  },

  watch: {
    appLoading: {
      handler: function (val) {
        if (!val) {
          swal.close();
        }
      },
      deep: true,
    },
  },
};
</script>
