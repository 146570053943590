import store from "../store";
import moment from "moment";

const formatDate = {
  install: (Vue) => {
    Vue.prototype.$formatDate = (value, format = "LLL", isUTC = false) => {
      const me = store.getters["profile/me"];
      let momentDate = moment(value);
      if (isUTC) {
        momentDate = momentDate.utc().local();
      }
      return momentDate.locale(me?.locale ?? "fr").format(format);
    };
  },
};

export default formatDate;
