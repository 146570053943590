export default {
  CAFETERIAS_LIST: "Liste des cafétérias",
  ADD_CAFETERIA: "Ajouter une cafétéria",
  CAFETERIA_ADDED: "Cafétéria ajouté",
  EDIT_CAFETERIA: "Modifier une cafétéria",
  CAFETERIA_UPDATED: "Cafétéria mise a jour",
  DELETE_THIS_CAFETERIA: "Supprimer cette cafétéria ?",
  CAFETERIA_DELETED: "Cafétéria supprimée",
  SERVICE_TYPE: "Type de service",
  SERVICE_TYPE_REGULAR: "Régulier",
  SERVICE_TYPE_WITH_SERVICE: "Avec service",
  SERVICE_TYPE_WITHOUT_SERVICE: "Sans service",
  SERVICE_TYPE_ONLINE_ORDER: "Commande en ligne",
  OPERATION_TYPE: "Type d'opération",
  OPERATION_TYPE_PRODUCTION: "Cafétéria de production",
  OPERATION_TYPE_SATELLITE: "Cafétéria satellite",
  OPERATION_TYPE_AUTONOMOUS: "Cafétéria automome",
  RELATED_PRODUCTION_CAFETERIA: "Cafétéria de production liée",
};
