<template>
  <base-dropdown
    v-if="
      $currentUserCan($permissions.PERM_VIEW_APP_DEFAULT) ||
      $currentUserCan($permissions.PERM_VIEW_APP_SALES) ||
      $currentUserCan($permissions.PERM_VIEW_APP_PURCHASES) ||
      $currentUserCan($permissions.PERM_VIEW_APP_INVENTORY) ||
      $currentUserCan($permissions.PERM_VIEW_APP_CRM) ||
      $currentUserCan($permissions.PERM_VIEW_APP_FILE_EXPLORER) ||
      $currentUserCan($permissions.PERM_VIEW_APP_CAFETERIA) ||
      $currentUserCan($permissions.PERM_VIEW_APP_RESELLER) ||
      $currentUserCan($permissions.PERM_VIEW_APP_ADMIN)
    "
    menu-classes="dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right"
    class="nav-item"
    tag="li"
    title-tag="a"
    title-classes="nav-link"
    icon="ni ni-app ni-2x"
  >
    <template>
      <div class="row shortcuts px-4">
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_DEFAULT)"
          :to="{ name: 'Dashboard' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar rounded-circle bg-success">
              <i class="ni ni-collection"></i>
            </span>
            <small>{{ $t("APPS.DASHBOARD") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_CAFETERIA)"
          :to="{ name: 'Cafeteria Page' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar rounded-circle bg-success">
              <i class="ni ni-shop"></i>
            </span>
            <small>{{ $t("APPS.CAFETERIA_MODULE") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_CRM)"
          :to="{ name: 'Crm Page' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar rounded-circle bg-success">
              <i class="fa fa-users"></i>
            </span>
            <small>{{ $t("APPS.CRM_MODULE") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_SALES)"
          :to="{ name: 'Sales Page' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar rounded-circle bg-success">
              <i class="ni ni-cart"></i>
            </span>
            <small>{{ $t("APPS.SALES_MODULE") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_PURCHASES)"
          :to="{ name: 'Purchases Page' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar rounded-circle bg-success">
              <i class="ni ni-basket"></i>
            </span>
            <small>{{ $t("APPS.PURCHASES_MODULE") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_INVENTORY)"
          :to="{ name: 'Inventory Page' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar rounded-circle bg-success">
              <i class="ni ni-app"></i>
            </span>
            <small>{{ $t("APPS.INVENTORY_MODULE") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_FILE_EXPLORER)"
          :to="{ name: 'Files Explorer' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar rounded-circle bg-success">
              <i class="ni ni-folder-17"></i>
            </span>
            <small>{{ $t("APPS.FILE_EXPLORER_MODULE") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_RESELLER)"
          :to="{ name: 'Reseller Page' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar rounded-circle bg-success">
              <i class="ni ni-shop"></i>
            </span>
            <small>{{ $t("APPS.RESELLER_MODULE") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_ADMIN)"
          :to="{ name: 'Admin Page' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar rounded-circle bg-gray">
              <i class="ni ni-settings-gear-65"></i>
            </span>
            <small>{{ $t("APPS.ADMIN_MODULE") }}</small>
          </a>
        </router-link>
      </div>
    </template>
  </base-dropdown>
</template>

<script>
const defaultAppName = process.env.VUE_APP_NAME;

export default {
  components: {},

  props: {},

  async created() {},

  computed: {},

  data() {
    return {
      defaultAppName: defaultAppName,
    };
  },

  methods: {},
};
</script>
