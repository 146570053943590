export default {
  CASH_REGISTER_READINGS_INVOICE_ITEM_LIST: "Liste des soumissions",
  ADD_CASH_REGISTER_READING_INVOICE_ITEM: "Ajouter une soumission",
  CASH_REGISTER_READING_INVOICE_ITEM_ADDED: "soumission ajoutée",
  EDIT_CASH_REGISTER_READING_INVOICE_ITEM: "Modifier la soumission",
  CASH_REGISTER_READING_INVOICE_ITEM_UPDATED: "soumission mise à jour",
  DELETE_THIS_CASH_REGISTER_READING_INVOICE_ITEM: "Supprimer cette soumission?",
  CASH_REGISTER_READING_INVOICE_ITEM_DELETED: "soumission supprimée",
  STARTS_AT: "Début",
  ENDS_AT: "Fin",
  CREATED_BY: "Crée par",
  UPDATED_BY: "Modifieé par",
  UPDATED_BY: "Modifieé par",
  AMOUNT: "Montant",
  TITLE: "Titre",
  DELETE_THIS_INVOICE: "Supprimer cette soumission ?"
};
