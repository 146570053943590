export default {
  TAX_GROUPS_LIST: "Liste des groupes de taxes",
  ADD_TAX_GROUP: "Ajouter un groupe de taxes",
  TAX_GROUP_ADDED: "Groupe de taxes ajouté",
  EDIT_TAX_GROUP: "Modifier un groupe de taxes",
  TAX_GROUPS_UPDATED: "Groupe de taxe mis à jour",
  DELETE_THIS_TAX_GROUP: "Supprimer ce groupe de taxes ?",
  TAX_GROUP_DELETED: "Groupe de taxes supprimée",
  ACTIVE: "Actif",
  COUNTRY_CODE: "Code pays",
};
