import store from "../store";

const fillUserResellerData = {
  install: (Vue) => {
    Vue.prototype.$fillUserResellerData = (data) => {
      const me = store.getters["profile/me"];
      if (!!me.reseller?.id) {
        data.reseller.id = me.reseller.id;
      }
      return data;
    };
  },
};

export default fillUserResellerData;
