export default {
  TAXES_LIST: "Liste des taxes",
  ADD_TAX: "Ajouter une taxe",
  TAX_ADDED: "Taxe ajoutée",
  EDIT_TAX: "Modifier une taxe",
  TAX_UPDATED: "Taxe mise à jour",
  DELETE_THIS_TAX: "Supprimer cette taxe ?",
  TAX_DELETED: "Taxe supprimée",
  ACTIVE: "Actif",
  LABEL: "Dénomination",
  TAX_NUMBER: "Numero de taxe",
  TAX_TYPE: "Type de taxe",
  TAX_TYPE_SALES: "Vente",
  TAX_TYPE_PURCHASES: "Achat",
  TAX_CALCULATION_TYPE: "Methode de calcul",
  TAX_CALCULATION_TYPE_AMOUNT: "Montant fixe",
  TAX_CALCULATION_TYPE_PERCENTAGE: "Pourcentage",
  TAX_CALCULATION_BASE: "Base de calcul",
  TAX_CALCULATION_BASE_BEFORE_TAX: "Montant avant taxe",
  TAX_CALCULATION_BASE_AFTER_TAX: "Montant apres taxe",
  VALUE: "Valeur",
  TAXES_UPDATED: "Taxe mise à jour",
};
