export default {
  ESTABLISHMENTS_LIST: "Liste des établissements",
  ADD_ESTABLISHMENT: "Ajouter un établissement",
  ESTABLISHMENT_ADDED: "Établissement ajouté",
  EDIT_ESTABLISHMENT: "Éditer un établissement",
  ESTABLISHMENT_UPDATED: "Établissement mis à jour",
  DELETE_THIS_ESTABLISHMENT: "Supprimer cet établissement ?",
  ESTABLISHMENT_DELETED: "Établissement supprimé",
  BILLING_INFORMATIONS_SAME_AS_ESTABLISHMENT:
    "Meme addresse que l'établissement",
  ESTABLISHMENT_TYPE_ELEMENTARY: "Primaire",
  ESTABLISHMENT_TYPE_SECONDARY: "Secondaire",
  ESTABLISHMENT_TYPE_COLLEGE: "Collégial",
  ESTABLISHMENT_TYPE_UNIVERSITY: "Universitaire",
  ESTABLISHMENT_TYPE_PROFESSIONAL: "Professionnel",
  ACTIVATE: "Activer",
  DESACTIVATE: "Désactiver",
  ESTABLISHMENT_TYPE: "Type d'établissement",
  ADD_INVOICE: "Ajouter une facture",
  ADD_SALES_ORDER: "Ajouter une soumission",
  ADD_PURCHASES_ORDER: "Ajouter une commande achat",
  MANAGERS: "Gestionnaires",
  COORDINATOR: "Coordination",
  ADMINISTRATION: "Administration",
  SCHOOL_LEVEL: " Niveau scolaire",
};
