export default {
  PERM_VIEW_APP_DEFAULT: "view app default",
  PERM_VIEW_APP_CRM: "view app crm",
  PERM_VIEW_APP_SALES: "view app sales",
  PERM_VIEW_APP_PURCHASES: "view app purchases",
  PERM_VIEW_APP_INVENTORY: "view app inventory",
  PERM_VIEW_APP_FILE_EXPLORER: "view app file explorer",
  PERM_VIEW_APP_RESELLER: "view app reseller",
  PERM_VIEW_APP_ADMIN: "view app admin",
  PERM_VIEW_APP_CAFETERIA: "view app cafeteria",
  PERM_VIEW_APP_REPORTS: 'view app reports',

  PERM_VIEW_MODULE_SUBSCRIPTIONS: "view module subscriptions",
  PERM_VIEW_ANY_SUBSCRIPTIONS: "view any subscriptions",
  PERM_VIEW_SUBSCRIPTIONS: "view subscriptions",
  PERM_CREATE_SUBSCRIPTIONS: "create subscriptions",
  PERM_EDIT_SUBSCRIPTIONS: "edit subscriptions",
  PERM_DELETE_SUBSCRIPTIONS: "delete subscriptions",

  PERM_VIEW_MODULE_PACKAGES: "view module packages",
  PERM_VIEW_ANY_PACKAGES: "view any packages",
  PERM_VIEW_PACKAGES: "view packages",
  PERM_CREATE_PACKAGES: "create packages",
  PERM_EDIT_PACKAGES: "edit packages",
  PERM_DELETE_PACKAGES: "delete packages",

  PERM_VIEW_MODULE_CUSTOMERS: "view module customers",
  PERM_VIEW_ANY_CUSTOMERS: "view any customers",
  PERM_VIEW_CUSTOMERS: "view customer",
  PERM_CREATE_CUSTOMERS: "create customer",
  PERM_EDIT_CUSTOMERS: "edit customer",
  PERM_DELETE_CUSTOMERS: "delete customer",

  PERM_VIEW_MODULE_ORGANIZATIONS: "view module organizations",
  PERM_VIEW_ANY_ORGANIZATIONS: "view any organizations",
  PERM_VIEW_ORGANIZATIONS: "view organization",
  PERM_CREATE_ORGANIZATIONS: "create organization",
  PERM_EDIT_ORGANIZATIONS: "edit organization",
  PERM_EDIT_OWN_ORGANIZATIONS: "edit own organization",
  PERM_DELETE_ORGANIZATIONS: "delete organization",
  PERM_EDIT_HIS_ORGANIZATIONS: "edit his organization",

  PERM_VIEW_MODULE_RESELLERS: "view module resellers",
  PERM_VIEW_ANY_RESELLERS: "view any resellers",
  PERM_VIEW_RESELLERS: "view resellers",
  PERM_CREATE_RESELLERS: "create resellers",
  PERM_EDIT_RESELLERS: "edit resellers",
  PERM_EDIT_OWN_RESELLERS: "edit own resellers",
  PERM_DELETE_RESELLERS: "delete resellers",

  PERM_VIEW_MODULE_RESELLER_PRODUCTS: "view module reseller products",
  PERM_VIEW_ANY_RESELLER_PRODUCTS: "view any reseller products",
  PERM_VIEW_RESELLER_PRODUCTS: "view reseller products",
  PERM_CREATE_RESELLER_PRODUCTS: "create reseller products",
  PERM_EDIT_RESELLER_PRODUCTS: "edit reseller products",
  PERM_DELETE_RESELLER_PRODUCTS: "delete reseller products",

  PERM_VIEW_MODULE_RESELLER_SERVICES: "view module reseller services",
  PERM_VIEW_ANY_RESELLER_SERVICES: "view any reseller services",
  PERM_VIEW_RESELLER_SERVICES: "view reseller services",
  PERM_CREATE_RESELLER_SERVICES: "create reseller services",
  PERM_EDIT_RESELLER_SERVICES: "edit reseller services",
  PERM_DELETE_RESELLER_SERVICES: "delete reseller services",

  PERM_VIEW_MODULE_RESELLER_INVOICES: "view module reseller invoices",
  PERM_VIEW_ANY_RESELLER_INVOICES: "view any reseller invoices",
  PERM_VIEW_RESELLER_INVOICES: "view reseller invoice",
  PERM_CREATE_RESELLER_INVOICES: "create reseller invoice",
  PERM_EDIT_RESELLER_INVOICES: "edit reseller invoice",
  PERM_DELETE_RESELLER_INVOICES: "delete reseller invoice",

  PERM_VIEW_MODULE_RESELLER_PAYMENTS: "view module reseller payments",
  PERM_VIEW_ANY_RESELLER_PAYMENTS: "view any reseller payments",
  PERM_VIEW_RESELLER_PAYMENTS: "view reseller payments",
  PERM_CREATE_RESELLER_PAYMENTS: "create reseller payments",

  PERM_VIEW_MODULE_USERS: "view module users",
  PERM_VIEW_ANY_USERS: "view any users",
  PERM_VIEW_USERS: "view users",
  PERM_CREATE_USERS: "create users",
  PERM_EDIT_USERS: "edit users",
  PERM_DELETE_USERS: "delete users",

  PERM_VIEW_MODULE_PRODUCTS: "view module products",
  PERM_VIEW_ANY_PRODUCTS: "view any products",
  PERM_VIEW_PRODUCTS: "view products",
  PERM_CREATE_PRODUCTS: "create products",
  PERM_EDIT_PRODUCTS: "edit products",
  PERM_DELETE_PRODUCTS: "delete products",

  PERM_VIEW_MODULE_ROLES: "view module roles",
  PERM_VIEW_ANY_ROLES: "view any roles",
  PERM_VIEW_ROLES: "view roles",
  PERM_CREATE_ROLES: "create roles",
  PERM_EDIT_ROLES: "edit roles",
  PERM_DELETE_ROLES: "delete roles",

  PERM_VIEW_ANY_PERMISSIONS: "view any permissions",
  PERM_VIEW_PERMISSIONS: "view permissions",

  PERM_VIEW_ANY_LOGS: "view any logs",
  PERM_VIEW_LOGS: "view logs",

  PERM_VIEW_MODULE_CONTACTS: "view module contacts",
  PERM_VIEW_ANY_CONTACTS: "view any contacts",
  PERM_VIEW_CONTACTS: "view contacts",
  PERM_CREATE_CONTACTS: "create contacts",
  PERM_EDIT_CONTACTS: "edit contacts",
  PERM_DELETE_CONTACTS: "delete contacts",

  PERM_VIEW_MODULE_SUPPLIERS: "view module suppliers",
  PERM_VIEW_ANY_SUPPLIERS: "view any suppliers",
  PERM_VIEW_SUPPLIERS: "view suppliers",
  PERM_CREATE_SUPPLIERS: "create suppliers",
  PERM_EDIT_SUPPLIERS: "edit suppliers",
  PERM_DELETE_SUPPLIERS: "delete suppliers",

  PERM_VIEW_MODULE_LOCATIONS: "view module locations",
  PERM_VIEW_ANY_LOCATIONS: "view any locations",
  PERM_VIEW_LOCATIONS: "view locations",
  PERM_CREATE_LOCATIONS: "create locations",
  PERM_EDIT_LOCATIONS: "edit locations",
  PERM_DELETE_LOCATIONS: "delete locations",
  PERM_EDIT_HIS_LOCATIONS: "edit his locations",

  PERM_VIEW_MODULE_FILES: "view module files",
  PERM_VIEW_ANY_FILES: "view any files",
  PERM_VIEW_FILES: "view files",
  PERM_CREATE_FILES: "create files",
  PERM_EDIT_FILES: "edit files",
  PERM_DELETE_FILES: "delete files",
  PERM_MANAGE_ACCESS_FILES: "manage access files",

  PERM_VIEW_MODULE_FOLDERS: "view module folders",
  PERM_VIEW_ANY_FOLDERS: "view any folders",
  PERM_VIEW_FOLDERS: "view folders",
  PERM_CREATE_FOLDERS: "create folders",
  PERM_EDIT_FOLDERS: "edit folders",
  PERM_DELETE_FOLDERS: "delete folders",
  PERM_MANAGE_ACCESS_FOLDERS: "manage access folders",
  PERM_MANAGE_LOCKED_FOLDERS: "manage locked folders",

  PERM_VIEW_MODULE_CAFETERIAS: "view module cafeterias",
  PERM_VIEW_ANY_CAFETERIAS: "view any cafeterias",
  PERM_VIEW_CAFETERIAS: "view cafeterias",
  PERM_CREATE_CAFETERIAS: "create cafeterias",
  PERM_EDIT_CAFETERIAS: "edit cafeterias",
  PERM_DELETE_CAFETERIAS: "delete cafeterias",

  PERM_VIEW_MODULE_ESTABLISHMENTS: "view module establishments",
  PERM_VIEW_MODULE_ESTABLISHMENTS_DIRECTORY:
    "view module establishments directory",
  PERM_VIEW_ANY_ESTABLISHMENTS: "view any establishments",
  PERM_VIEW_ESTABLISHMENTS: "view establishments",
  PERM_CREATE_ESTABLISHMENTS: "create establishments",
  PERM_EDIT_ESTABLISHMENTS: "edit establishments",
  PERM_DELETE_ESTABLISHMENTS: "delete establishments",

  PERM_VIEW_MODULE_SERVICECENTERS: "view module service centers",
  PERM_VIEW_ANY_SERVICECENTERS: "view any service centers",
  PERM_VIEW_SERVICECENTERS: "view service centers",
  PERM_CREATE_SERVICECENTERS: "create service centers",
  PERM_EDIT_SERVICECENTERS: "edit service centers",
  PERM_DELETE_SERVICECENTERS: "delete service centers",

  PERM_VIEW_MODULE_WAREHOUSES: "view module warehouses",
  PERM_VIEW_ANY_WAREHOUSES: "view any warehouses",
  PERM_VIEW_WAREHOUSES: "view warehouses",
  PERM_CREATE_WAREHOUSES: "create warehouses",
  PERM_EDIT_WAREHOUSES: "edit warehouses",
  PERM_DELETE_WAREHOUSES: "delete warehouses",

  PERM_VIEW_MODULE_PURCHASES_ORDERS: "view module purchases orders",
  PERM_VIEW_ANY_PURCHASES_ORDERS: "view any purchases orders",
  PERM_VIEW_PURCHASES_ORDERS: "view purchases orders",
  PERM_CREATE_PURCHASES_ORDERS: "create purchases orders",
  PERM_EDIT_PURCHASES_ORDERS: "edit purchases orders",
  PERM_DELETE_PURCHASES_ORDERS: "delete purchases orders",

  PERM_VIEW_MODULE_PURCHASES_INVOICES: "view module purchases invoices",
  PERM_VIEW_ANY_PURCHASES_INVOICES: "view any purchases invoices",
  PERM_VIEW_PURCHASES_INVOICES: "view purchases invoices",
  PERM_CREATE_PURCHASES_INVOICES: "create purchases invoices",
  PERM_EDIT_PURCHASES_INVOICES: "edit purchases invoices",
  PERM_DELETE_PURCHASES_INVOICES: "delete purchases invoices",

  PERM_VIEW_MODULE_PURCHASES_DELIVERIES: "view module purchases deliveries",
  PERM_VIEW_ANY_PURCHASES_DELIVERIES: "view any purchases deliveries",
  PERM_VIEW_PURCHASES_DELIVERIES: "view purchases deliveries",
  PERM_CREATE_PURCHASES_DELIVERIES: "create purchases deliveries",
  PERM_EDIT_PURCHASES_DELIVERIES: "edit purchases deliveries",
  PERM_DELETE_PURCHASES_DELIVERIES: "delete purchases deliveries",

  PERM_VIEW_MODULE_PURCHASES_PAYMENTS: "view module purchases payments",
  PERM_VIEW_ANY_PURCHASES_PAYMENTS: "view any purchases payments",
  PERM_VIEW_PURCHASES_PAYMENTS: "view purchases payments",
  PERM_CREATE_PURCHASES_PAYMENTS: "create purchases payments",
  PERM_EDIT_PURCHASES_PAYMENTS: "edit purchases payments",
  PERM_DELETE_PURCHASES_PAYMENTS: "delete purchases payments",

  PERM_VIEW_MODULE_SALES_ORDERS: "view module sales orders",
  PERM_VIEW_ANY_SALES_ORDERS: "view any sales orders",
  PERM_VIEW_SALES_ORDERS: "view sales orders",
  PERM_CREATE_SALES_ORDERS: "create sales orders",
  PERM_EDIT_SALES_ORDERS: "edit sales orders",
  PERM_DELETE_SALES_ORDERS: "delete sales orders",

  PERM_VIEW_MODULE_SALES_INVOICES: "view module sales invoices",
  PERM_VIEW_ANY_SALES_INVOICES: "view any sales invoices",
  PERM_VIEW_SALES_INVOICES: "view sales invoices",
  PERM_CREATE_SALES_INVOICES: "create sales invoices",
  PERM_EDIT_SALES_INVOICES: "edit sales invoices",
  PERM_DELETE_SALES_INVOICES: "delete sales invoices",

  PERM_VIEW_MODULE_SALES_DELIVERIES: "view module sales deliveries",
  PERM_VIEW_ANY_SALES_DELIVERIES: "view any sales deliveries",
  PERM_VIEW_SALES_DELIVERIES: "view sales deliveries",
  PERM_CREATE_SALES_DELIVERIES: "create sales deliveries",
  PERM_EDIT_SALES_DELIVERIES: "edit sales deliveries",
  PERM_DELETE_SALES_DELIVERIES: "delete sales deliveries",

  PERM_VIEW_MODULE_SALES_PAYMENTS: "view module sales payments",
  PERM_VIEW_ANY_SALES_PAYMENTS: "view any sales payments",
  PERM_VIEW_SALES_PAYMENTS: "view sales payments",
  PERM_CREATE_SALES_PAYMENTS: "create sales payments",
  PERM_EDIT_SALES_PAYMENTS: "edit sales payments",
  PERM_DELETE_SALES_PAYMENTS: "delete sales payments",

  PERM_VIEW_MODULE_STOCK_MOVEMENTS: "view module stock movements",
  PERM_VIEW_ANY_STOCK_MOVEMENTS: "view any stock movements",
  PERM_VIEW_STOCK_MOVEMENTS: "view stock movements",
  PERM_CREATE_STOCK_MOVEMENTS: "create stock movements",
  PERM_CREATE_STOCK_MOVEMENTS_FROM_EXISTING_QUANTITY: "create stock movements from existing quantity",
  PERM_EDIT_STOCK_MOVEMENTS: "edit stock movements",
  PERM_DELETE_STOCK_MOVEMENTS: "delete stock movements",

  PERM_VIEW_MODULE_IMPORTS: "view module imports",
  PERM_VIEW_ANY_IMPORTS: "view any imports",
  PERM_VIEW_IMPORTS: "view imports",
  PERM_CREATE_IMPORTS: "create imports",
  PERM_EDIT_IMPORTS: "edit imports",
  PERM_DELETE_IMPORTS: "delete imports",

  PERM_VIEW_MODULE_SUPPLY_RULES: "view module supply rules",
  PERM_VIEW_ANY_SUPPLY_RULES: "view any supply rules",
  PERM_VIEW_SUPPLY_RULES: "view supply rules",
  PERM_CREATE_SUPPLY_RULES: "create supply rules",
  PERM_EDIT_SUPPLY_RULES: "edit supply rules",
  PERM_DELETE_SUPPLY_RULES: "delete supply rules",

  PERM_VIEW_MODULE_CATEGORIES: "view module categories",
  PERM_VIEW_ANY_CATEGORIES: "view any categories",
  PERM_VIEW_CATEGORIES: "view categories",
  PERM_CREATE_CATEGORIES: "create categories",
  PERM_EDIT_CATEGORIES: "edit categories",
  PERM_DELETE_CATEGORIES: "delete categories",

  PERM_VIEW_MODULE_TAXES: "view module taxes",
  PERM_VIEW_ANY_TAXES: "view any taxes",
  PERM_VIEW_TAXES: "view taxes",
  PERM_CREATE_TAXES: "create taxes",
  PERM_EDIT_TAXES: "edit taxes",
  PERM_DELETE_TAXES: "delete taxes",

  PERM_VIEW_MODULE_PAYMENT_TERMS: "view module payment terms",
  PERM_VIEW_ANY_PAYMENT_TERMS: "view any payment terms",
  PERM_VIEW_PAYMENT_TERMS: "view payment terms",
  PERM_CREATE_PAYMENT_TERMS: "create payment terms",
  PERM_EDIT_PAYMENT_TERMS: "edit payment terms",
  PERM_DELETE_PAYMENT_TERMS: "delete payment terms",

  PERM_VIEW_MODULE_OPTIONS: "view module options",
  PERM_EDIT_OPTIONS: "edit options",

  PERM_VIEW_MODULE_EVENTS: "view module events",
  PERM_VIEW_ANY_EVENTS: "view any events",
  PERM_VIEW_EVENTS: "view events",
  PERM_CREATE_EVENTS: "create events",
  PERM_EDIT_EVENTS: "edit events",
  PERM_DELETE_EVENTS: "delete events",

  PERM_VIEW_MODULE_CASH_REGISTERS: "view module cash registers",
  PERM_VIEW_ANY_CASH_REGISTERS: "view any cash registers",
  PERM_VIEW_CASH_REGISTERS: "view cash registers",
  PERM_CREATE_CASH_REGISTERS: "create cash registers",
  PERM_EDIT_CASH_REGISTERS: "edit cash registers",
  PERM_DELETE_CASH_REGISTERS: "delete cash registers",

  PERM_VIEW_MODULE_CASH_REGISTER_READINGS: "view module cash register readings",
  PERM_VIEW_ANY_CASH_REGISTER_READINGS: "view any cash registe readings",
  PERM_VIEW_CASH_REGISTER_READINGS: "view cash register readings",
  PERM_CREATE_CASH_REGISTER_READINGS: "create cash register readings",
  PERM_EDIT_CASH_REGISTER_READINGS: "edit cash register readings",
  PERM_DELETE_CASH_REGISTER_READINGS: "delete cash register readings",

  PERM_VIEW_REPORTS_CASH_REGISTER_READINGS: 'view reports cash register readings',
};

export const SCOPE_ADMIN = 0;
export const SCOPE_RESELLER = 20;
export const SCOPE_ORGANIZATION = 40;
export const SCOPE_NONE = 50;
